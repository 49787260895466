import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { ImgWrapper } from "../../blocks";
export const _frontmatter = {
  "title": "Improve Your Storybook Navigation",
  "date": "2020-06-03T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h5></h5>
    <p>{`After adding several components for UI parts to my storybook Buttons, Titles, etc. and components that corresponds to specific application logic custom Lists and Forms, Charts etc. I understand that mixing all of these components in one place looks messy.`}</p>
    <p>{`After a small research, I found a few good structured examples, `}<a parentName="p" {...{
        "href": "https://wix-style-react.now.sh/?path=/story/introduction-getting-started--getting-started"
      }}>{`WixStyleReact`}</a>{` and `}<a parentName="p" {...{
        "href": "https://appnexus.github.io/lucid/?path=/docs/visualizations-bars--basic"
      }}>{`Lucid UI`}</a>{`, that helps
me to understand how to structure my storybook navigation:`}</p>
    <p><strong parentName="p">{`WixStyleReact`}</strong></p>
    <ImgWrapper width={400} mdxType="ImgWrapper">
      <p><img parentName="p" {...{
          "src": "/wix-navigation.png",
          "alt": null
        }}></img></p>
    </ImgWrapper>
    <p>{`To creating High Level navigation header, we are using this syntax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`WIX screenshot  (Introduction section):
'Introduction|Getting Started',
'Introduction|Playground',
...
`}</code></pre>
    <p>{`Code of our storie, will look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// GettingStarded.stories.js
import React from "react";
import GettingStarded from "./GettingStarded";

export default {
  title: "Introduction|Getting Started",
};

export const Default = () => <GettingStarded />;
`}</code></pre>
    <p>{`If we want to make a folder with a list of components, just add one more subdirectory using slash (`}<inlineCode parentName="p">{`/`}</inlineCode>{`) symbol. Will be looking like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`WIX screenshot (Components API section):
'Components API|Components/Form'
'Components API|Components/Modal'
...
`}</code></pre>
    <p>{`Code of our storie, will look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// GettingStarded.stories.js
import React from "react";
import Form from "./components/Form";

export default {
  title: "Components API|Components/Form",
};

export const Default = () => <Form />;
`}</code></pre>
    <p>{`If you wanna read more about this pattern, here is link on the official documentation `}<a parentName="p" {...{
        "href": "https://storybook.js.org/docs/basics/writing-stories/#story-hierarchy"
      }}>{`Story hierarchy`}</a>{`.`}</p>
    <p>{`One more example of components library:`}</p>
    <p><strong parentName="p">{`Lucid UI`}</strong></p>
    <ImgWrapper width={400} mdxType="ImgWrapper">
      <p><img parentName="p" {...{
          "src": "/lucidui-navigation.png",
          "alt": "Lucid UI"
        }}></img></p>
    </ImgWrapper>
    <hr></hr>
    <p>{`References:`}</p>
    <ol>
      <li parentName="ol">{`Wix Components library - `}<a parentName="li" {...{
          "href": "https://github.com/wix/wix-style-react"
        }}>{`https://github.com/wix/wix-style-react`}</a></li>
      <li parentName="ol">{`Lucid UI Components library - `}<a parentName="li" {...{
          "href": "https://github.com/appnexus/lucid"
        }}>{`https://github.com/appnexus/lucid`}</a></li>
      <li parentName="ol">{`Storybook official docs - `}<a parentName="li" {...{
          "href": "https://storybook.js.org/docs/basics/writing-stories/#story-hierarchy"
        }}>{`https://storybook.js.org/docs/basics/writing-stories/#story-hierarchy`}</a></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      